import { helpers, maxLength, requiredIf } from 'vuelidate/lib/validators';

const customPendingRebootNotificationMaxDelays = {
  required: requiredIf(
    (nestedModel) => !!nestedModel.pending_reboot_deferral_enabled,
  ),
  between: (value, nestedModel) =>
    !nestedModel.pending_reboot_deferral_enabled ||
    (nestedModel.pending_reboot_deferral_enabled && value >= 1 && value <= 10),
};

const defermentBetween1and24Hours = {
  betweenOne: (value = []) =>
    !helpers.req(value[0]) || (value[0] >= 1 && value[0] <= 24),
  betweenTwo: (value = []) =>
    !helpers.req(value[1]) || (value[1] >= 1 && value[1] <= 24),
  betweenThree: (value = []) =>
    !helpers.req(value[2]) || (value[2] >= 1 && value[2] <= 24),
};

const uniqueDeferments = {
  unique: (value = []) => {
    const deferments = value.filter((period) => period);

    return deferments.length === [...new Set(deferments)].length;
  },
};

const customPendingRebootNotificationDefermentPeriods = {
  ...defermentBetween1and24Hours,
  required: (values, nestedModel) => {
    return nestedModel.notify_deferred_reboot_user &&
      nestedModel.pending_reboot_deferral_enabled
      ? values.some((v) => !!v)
      : true;
  },
  ...uniqueDeferments,
};

const requiredIfNotifyDeferredRebootUserEnabled = requiredIf(
  (nestedModel) => !!nestedModel.notify_deferred_reboot_user,
);

const customPendingRebootNotificationMessageMac = {
  required: requiredIfNotifyDeferredRebootUserEnabled,
  maxLength: maxLength(70),
};

const customPendingRebootNotificationMessage = {
  required: requiredIfNotifyDeferredRebootUserEnabled,
  maxLength: maxLength(125),
};

export const rebootNotificationValidation = {
  notify_user: {
    required: (value, nestedModel) => {
      return (
        !nestedModel.user_notification ||
        (!!nestedModel.user_notification &&
          (!!nestedModel.notify_reboot_user || value === true))
      );
    },
  },
  notify_reboot_user: {
    required: (value, nestedModel) => {
      return (
        !nestedModel.user_notification ||
        (!!nestedModel.user_notification &&
          (!!nestedModel.notify_user || value === true))
      );
    },
  },
  custom_notification_patch_message: {
    required: requiredIf((nestedModel) => !!nestedModel.notify_user),
    maxLength: maxLength(125),
  },
  custom_notification_patch_message_mac: {
    required: requiredIf((nestedModel) => !!nestedModel.notify_user),
    maxLength: maxLength(70),
  },
  custom_notification_reboot_message: {
    required: requiredIf(
      (nestedModel) =>
        !!nestedModel.notify_reboot_user && !!nestedModel.install_notification,
    ),
    maxLength: maxLength(125),
  },
  custom_notification_reboot_message_mac: {
    required: requiredIf(
      (nestedModel) =>
        !!nestedModel.notify_reboot_user && !!nestedModel.install_notification,
    ),
    maxLength: maxLength(70),
  },
  custom_pending_reboot_notification_message:
    customPendingRebootNotificationMessage,
  custom_pending_reboot_notification_message_mac:
    customPendingRebootNotificationMessageMac,
  custom_notification_deferment_periods: {
    ...defermentBetween1and24Hours,
    required: (values, nestedModel) => {
      return (nestedModel.notify_user || nestedModel.notify_reboot_user) &&
        nestedModel.install_deferral_enabled &&
        values
        ? values.some((v) => !!v)
        : true;
    },
    ...uniqueDeferments,
  },
  custom_notification_max_delays: {
    required: requiredIf(
      (nestedModel) => !!nestedModel.install_deferral_enabled,
    ),
    between: (value, nestedModel) =>
      !nestedModel.install_deferral_enabled ||
      (nestedModel.install_deferral_enabled && value >= 1 && value <= 10),
  },
  custom_pending_reboot_notification_deferment_periods:
    customPendingRebootNotificationDefermentPeriods,
  custom_pending_reboot_notification_max_delays:
    customPendingRebootNotificationMaxDelays,
};

export const workletRebootNotificationValidation = {
  custom_pending_reboot_notification_message:
    customPendingRebootNotificationMessage,
  custom_pending_reboot_notification_message_mac:
    customPendingRebootNotificationMessageMac,
  custom_pending_reboot_notification_deferment_periods:
    customPendingRebootNotificationDefermentPeriods,
  custom_pending_reboot_notification_max_delays:
    customPendingRebootNotificationMaxDelays,
};
