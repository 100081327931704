export const defaultNotificationDuration = 15;

export class RebootConfigurationForm {
  /* Reboot Notification Values - Start */
  auto_reboot = true;

  notify_reboot_user = true;

  notify_deferred_reboot_user = true;

  custom_pending_reboot_notification_message = '';

  custom_pending_reboot_notification_message_mac = '';

  custom_pending_reboot_notification_deferment_periods = [1, 4, 8];

  custom_pending_reboot_notification_max_delays = 3;

  notify_deferred_reboot_user_auto_deferral_enabled = false;

  notify_deferred_reboot_user_message_timeout = defaultNotificationDuration;

  pending_reboot_deferral_enabled = true;

  notify_user?: boolean;

  custom_notification_patch_message?: string;

  custom_notification_patch_message_mac?: string;

  custom_notification_reboot_message?: string;

  custom_notification_reboot_message_mac?: string;

  custom_notification_deferment_periods?: number[];

  custom_notification_max_delays?: number | null;

  notify_user_message_timeout?: number;

  notify_user_auto_deferral_enabled?: boolean;

  install_deferral_enabled?: boolean;
  /* Reboot Notification Values - End */

  install_notification?: boolean;

  install_notification_all?: boolean;

  constructor(init: Partial<RebootConfigurationForm>) {
    Object.assign(this, init);
  }
}
